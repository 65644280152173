import CSS from "csstype";

const separatorStyle: CSS.Properties = {
  height: "1rem"
};

function SeparatorView() {
  return (
    <div style={separatorStyle}></div>
  );
}

export default SeparatorView;