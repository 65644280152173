import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Button,
  Checkbox,
  Container,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  Link,
  OutlinedInput,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef } from "react";
import { useHistory } from "react-router";
import { appMarginTop } from "../../assets/css/globalCss";
import { ACCESS_TOKEN_KEY } from "../../data/config/services/Client";
import OAuthService from "../../data/config/services/loginServices";
import { RegisterBody } from "../../data/dto/interfaces";
import { getUserAction } from "../../data/redux/actions/userActions";
import { useAppDispatch, useAppSelector } from "../../data/redux/stores/hooks";
import { placesLink } from "../../routes";
import SeparatorView from "../components/SeparatorView";

interface State extends RegisterBody{
  name: string;
  lastname: string;
  document: string;
  phone: string;
  email: string;
  password: string;
  c_password: string;
  showPassword: boolean;
  ruc: string;
  denomination: string;
}

interface StateError {
  name: boolean;
  lastname: boolean;
  document: boolean;
  phone: boolean;
  email: boolean;
  password: boolean;
  c_password: boolean;
}

interface StateMessage {
  name: string;
  lastname: string;
  document: string;
  phone: string;
  email: string;
  password: string;
  c_password: string;
}

export default function RegisterScreen() {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const user = useAppSelector(selector => selector.userReducer.user);
  const [acepted, setAcepted] = React.useState(false);
  const [values, setValues] = React.useState<State>({
    name: '',
    lastname: '',
    document: '',
    phone: '',
    email: '',
    password: '',
    c_password: '',
    showPassword: false,
    ruc: '',
    denomination: '',
  });

  useEffect(() => {
    if (user) {
      setValues({
        name: user.name,
        lastname: user.lastname,
        document: user.document,
        phone: user.phone,
        email: user.email,
        password: '',
        c_password: '',
        showPassword: false,
        ruc: user.ruc,
        denomination: user.denomination,
      })
    }
  }, [user]);

  const newStateError = {
    name: false,
    lastname: false,
    document: false,
    phone: false,
    email: false,
    password: false,
    c_password: false,
  };

  const [valuesError, setValuesError] = React.useState<StateError>(newStateError);

  const [valuesMessage, setValuesMessage] = React.useState<StateMessage>({
    name: '',
    lastname: '',
    document: '',
    phone: '',
    email: '',
    password: '',
    c_password: '',
  });

  const handleChange =
    (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setValuesError({ ...valuesError, [prop]: event.target.value === '' });
      setValuesMessage({ ...valuesMessage, [prop]: event.target.value === '' ? '* requerido' : ''})
      setValues({ ...values, [prop]: event.target.value });
    };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  let nameImput = useRef();

  const [expandedRuc, setExpandedRuc] = React.useState<string | false>('panel1');

  const handleChangeRuc =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpandedRuc(newExpanded ? panel : false);
    };
    
  return (
    <Container maxWidth="sm" style={appMarginTop}>
      <TextField
        inputRef={nameImput}
        label="Nombre"
        id="nameInput"
        fullWidth
        value={values.name}
        onChange={handleChange('name')}
        error={valuesError.name}
        helperText={valuesMessage.name}
        required
      />
      <SeparatorView />
      <TextField
        label="Apellido"
        id="lastnameInput"
        fullWidth
        value={values.lastname}
        onChange={handleChange('lastname')}
        error={valuesError.lastname}
        helperText={valuesMessage.lastname}
        required
      />
      <SeparatorView />
      <TextField
        label="Documento"
        id="documentInput"
        fullWidth
        value={values.document}
        onChange={handleChange('document')}
        error={valuesError.document}
        helperText={valuesMessage.document}
        required
      />
      <SeparatorView />
      <TextField
        label="Teléfono"
        id="phoneInput"
        fullWidth
        value={values.phone}
        onChange={handleChange('phone')}
        error={valuesError.phone}
        helperText={valuesMessage.phone}
        required
      />
      <SeparatorView />
      <TextField
        label="Email"
        id="emailInput"
        fullWidth
        value={values.email}
        onChange={handleChange('email')}
        error={valuesError.email}
        helperText={valuesMessage.email}
        required
      />
      
      <SeparatorView />
      {!user && (
        <div>
          <FormControl sx={{ mt: 1, width: '100%' }} variant="outlined">
            <InputLabel htmlFor="passwordInput">Contraseña</InputLabel>
            <OutlinedInput
              id="passwordInput"
              type={values.showPassword ? 'text' : 'password'}
              value={values.password}
              fullWidth
              onChange={handleChange('password')}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {values.showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label="Contraseña"
              error={valuesError.password}
              required
            />
          </FormControl>
          {valuesError.password && (
            <small style={{
              color: '#d32f2f',
              fontSize: '0.75rem',
              fontWeight: '400',
              paddingLeft: 14,
            }}>{valuesMessage.password}</small>
          )}

          <SeparatorView />
          <FormControl sx={{ mt: 1, width: '100%' }} variant="outlined">
            <InputLabel htmlFor="repeatPasswordInput">Repetir Contraseña</InputLabel>
            <OutlinedInput
              id="repeatPasswordInput"
              type={values.showPassword ? 'text' : 'password'}
              value={values.c_password}
              fullWidth
              onChange={handleChange('c_password')}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {values.showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label="Repetir Contraseña"
              error={values.password !== values.c_password}
              required
            />
          </FormControl>
          {valuesError.c_password && (
            <small style={{
              color: '#d32f2f',
              fontSize: '0.75rem',
              fontWeight: '400',
              paddingLeft: 14,
            }}>{valuesMessage.c_password}</small>
          )}

          <SeparatorView />
      
          <Accordion expanded={expandedRuc === 'panelFacturacion'} onChange={handleChangeRuc('panelFacturacion')}>
            <AccordionSummary aria-controls="panelFacturaciond-content" id="panelFacturaciond-header">
              <Typography>Datos de Facturación</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
              <TextField
                label="RUC"
                id="rucInput"
                fullWidth
                value={values.ruc}
                onChange={handleChange('ruc')}
              />

              <SeparatorView />
              <TextField
                label="Denominación"
                id="denominationInput"
                fullWidth
                value={values.denomination}
                onChange={handleChange('denomination')}
              />
              </Typography>
            </AccordionDetails>
          </Accordion>
          <SeparatorView />
        </div>
      )}
        <>
          <div style={{textAlign: 'center'}}>
            <Alert severity={acepted ? 'success' : 'warning'}>
              <Checkbox
                value={acepted}
                onChangeCapture={() => {
                  setAcepted(!acepted);
                }}
              />
              He leido y acepto los <Link target='_blank' href='https://tup.com.py/terminos_y_condiciones'>términos y condiciones del servicio</Link>.
            </Alert>
          </div>
          <SeparatorView />
          <Button
            disabled={!acepted}
            fullWidth
            variant="contained"
            onClick={() => {
              if (user) {
                OAuthService.save(values).then(data => {
                  // dispatch get user data
                  // dispatch(getUserAction());
                  if (data.errors) {
                    const errors = data.errors;
                    // console.error(errors);
                    const keys = Object.keys(errors);
                    const tmpMsg: StateMessage = valuesMessage;
                    const tmpError: StateError = newStateError;
                    for (let key of keys) {
                      tmpMsg[key as keyof StateMessage] = errors[key].join(' | ');
                      tmpError[key as keyof StateError] = true;
                    }
                    // console.log(tmpMsg);
                    setValuesMessage(tmpMsg);
                    setValuesError(tmpError);
                  } else if(data.success) {
                    dispatch(getUserAction());
                    history.goBack();
                  } else {
                    // console.log('no pasa nada');
                  }
                }).catch(e => {
                  // console.error(e);
                });
              } else {
                OAuthService.register(values).then(data => {
                  // dispatch get user data
                  // dispatch(getUserAction());
                  if (data.errors) {
                    // console.error(data.errors);
                    const keys = Object.keys(data.errors);
                    const tmpMsg: StateMessage = valuesMessage;
                    const tmpError: StateError = newStateError;
                    for (let key of keys) {
                      tmpMsg[key as keyof StateMessage] = data.errors[key].join(' | ');
                      tmpError[key as keyof StateError] = true;
                    }
                    // console.log(tmpMsg);
                    setValuesMessage(tmpMsg);
                    setValuesError(tmpError);
                  } else if (data.token && data.token.length > 0) {
                    localStorage.setItem(ACCESS_TOKEN_KEY, data.token);
                    window.location.href = placesLink.path;
                  } else {
                    // entonces pasó algo?
                  }
                }).catch(e => {
                  // console.error(e);
                });
              }
            }}
            >
              {user ? ('GUARDAR') : ('REGISTRAR')}
          </Button>
        </>
    </Container>
  );
}
