import User from "../../model/User";

export const USER_LOADING = 'USER_LOADING';
export const USER_SUCCESS = 'USER_SUCCESS';
export const USER_FAIL = 'BANNER_FAIL';
export const USER_RESET = 'BANNER_RESET';

export const IS_USER_LOADING = 'IS_USER_LOADING';

export interface UserLoading {
  type: typeof USER_LOADING;
}

export interface UserFail {
  type: typeof USER_FAIL;
}

export interface UserSuccess {
  type: typeof USER_SUCCESS;
  payload: User;
}

export interface UserReset {
  type: typeof USER_RESET;
}

export type UserDispatchTypes =
  | UserLoading
  | UserFail
  | UserSuccess
  | UserReset;