import Place from '../../model/Place';
import {
  PLACE_LOADING,
  PLACE_SUCCESS,
  PLACE_FAIL,
  PLACE_RESET,
  PlaceDispatchTypes,
  PLACE_LETTER,
  Letters,
  SET_PLACE,
} from '../types/savePlace';

interface IinitialState {
  place?: Place;
  letter?: Letters;
  loading: boolean;
  error: boolean;
  logedIn: boolean;
}

const initialState: IinitialState = {
  place: undefined,
  letter: undefined,
  loading: false,
  error: false,
  logedIn: false,
};
const placeReducer = (
  state: IinitialState = initialState,
  action: PlaceDispatchTypes,
): IinitialState => {
  switch (action.type) {
    case PLACE_FAIL:
      return {...state, loading: false, error: true};
    case PLACE_LOADING:
      return {...state, loading: true};
    case PLACE_SUCCESS:
      return {
        ...state,
        loading: false,
        place: action.payload,
        error: false,
        logedIn: true,
      };
      case SET_PLACE:
        return {
          ...state,
          place: action.payload,
        };
    case PLACE_LETTER:
      return {
        ...state,
        loading: false,
        letter: action.payload,
        error: false,
        logedIn: true,
      };
    case PLACE_RESET:
        return {
          ...state,
          loading: false,
          place: undefined,
          error: false,
          logedIn: false,
        };
    default:
      return state;
  }
};

export default placeReducer;