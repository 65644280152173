import AccountScreen from "./ui/screens/AccountScreen";
import LoginScreen from "./ui/screens/LoginScreen";
import PlaceFormScreen from "./ui/screens/PlaceFormScreen";
import PlacesScreen from "./ui/screens/PlacesScreen";
import RegisterScreen from "./ui/screens/RegisterScreen";
import HomeScreen from "./ui/screens/HomeScreen";
import FaqScreen from "./ui/screens/FaqScreen";

export const inicioLink = {
  path: "/inicio",
  component: HomeScreen
};

export const faqLink = {
  path: "/preguntas_frecuentes",
  component: FaqScreen
};

export const homeLink = {
  path: "/",
  component: HomeScreen
};

export const placesEditALink = {
  path: "/formulario_lugar",
  component: PlaceFormScreen,
};

export const placesLink = {
  path: "/lugares",
  component: PlacesScreen,
};

export const accountLink = {
  path: "/cuenta",
  component: AccountScreen
};

export const loginLink = {
  path: "/iniciar",
  component: LoginScreen
};

export const registerLink = {
  path: "/registro",
  component: RegisterScreen
};


export const routes = [inicioLink, faqLink, placesLink, accountLink, placesEditALink, loginLink, registerLink, homeLink];

  
// function Places({ routes }: any) {
//   return (
//     <Switch>
//       {routes.map((_route: any, i: any) => (
//         <RouteWithSubRoutes key={i} {...routes} />
//       ))}
//     </Switch>
//   );
// }
