import {AxiosResponse} from 'axios';
import { RegisterBody, RegisterResponse, SaveUserResponse } from '../../dto/interfaces';
import User from '../../model/User';
// import Product from '../../models/Product';
import client, { ACCESS_TOKEN_KEY } from './Client';

const OAuthService = {
  register: async (registerData: RegisterBody): Promise<RegisterResponse> => {
    // console.log('OAuthService.register()', {registerData});
    return client
      .post('/api/v1/register', registerData)
      .then((res: AxiosResponse<RegisterResponse>) => {
        // console.log('OAuthService.register()', {res})
        if (res.data.token) {
          localStorage.setItem(ACCESS_TOKEN_KEY, res.data.token);
        }
        return res.data;
      })
      .catch(e => {
        // console.error('OAuthService.register()', e);
        return e;
      });
  },
  save: async (saveData: RegisterBody): Promise<SaveUserResponse> => {
    // console.log('OAuthService.save()', {saveData});
    return client
      .post('/api/v1/me/save', saveData)
      .then((res: AxiosResponse<SaveUserResponse>) => {
        // console.log('OAuthService.save()', {res});
        return res.data;
      })
      .catch(e => {
        // console.error('OAuthService.save()', e);
        return e;
      });
  },
  // login: async (authData: OauthTokenParams): Promise<LoginResponse> => {
  //   console.log('OAuthService.login()', {authData});
  //   // return client
  //   //   .post('/api/v1/login', {
  //   //     username: authData.username,
  //   //     password: authData.password,
  //   //     // grant_type: "password",
  //   //     // client_id: 2,
  //   //     // client_secret: "jH7hf105lDwPs3BTpG7SJxP7vByGslT3LLN1Ss1p",
  //   //     // scope: "*",
  //   //   })
  //   //   .then((res: AxiosResponse<LoginResponse>) => {
  //   //     console.log('OAuthService.login()', {res})
  //   //     if (res.data.token !== undefined) {
  //   //       localStorage.setItem(ACCESS_TOKEN_KEY, res.data.token);
  //   //     }
  //   //     return res;
  //   //   })
  //   //   .catch(e => {
  //   //     console.error('OAuthService.login()', e);
  //   //   });
  // },
  me: async (): Promise<User> => {
    // console.log('OAuthService.me()');
    return client
      .get('/api/v1/me')
      .then((res: AxiosResponse<{user: User}>) => {
        // console.log('OAuthService.me()', {res})
        return res.data.user;
      })
      .catch(e => {
        // console.error('OAuthService.me()', e);
        throw new Error(e);
      });
  },
};

export default OAuthService;
