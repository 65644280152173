import {
  Alert,
  Autocomplete,
  Button,
  Container,
  IconButton,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import SeparatorView from "../components/SeparatorView";
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import React from "react";
import { GOOGLE_APY_KEY } from "../../data/google";
import MapModal from "../components/MapModal";
import { appMarginTop } from "../../assets/css/globalCss";
import { useAppSelector } from "../../data/redux/stores/hooks";
import { Letters } from "../../data/redux/types/savePlace";
import PlacesService from "../../data/config/services/savePlaceServices";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import { getUserAction } from "../../data/redux/actions/userActions";
import CloseIcon from '@mui/icons-material/Close';

export default function PlaceFormScreen() {
  const dispatch = useDispatch();
  const history = useHistory();
  type LatLng = {
    lat: number,
    lng: number,
  }
  const defaultLatLng = React.useMemo(() => { return {
    lat: -25.277554,
    lng: -57.637893,
  }}, []);
  const [open, setOpen] = React.useState(false);
  const [openSnackBar, setOpenSnackBar] = React.useState(false);
  const geocoder = new google.maps.Geocoder();
  const [clicks, setClicks] = React.useState<google.maps.LatLng[]>([]);
  const [zoom, setZoom] = React.useState(14); // initial zoom
  const [center, setCenter] = React.useState<google.maps.LatLngLiteral>(defaultLatLng);
  const [latLng, setLatLng] = React.useState<LatLng>();

  const letter = useAppSelector(reducers => reducers.savePlaceReducer.letter);
  const place = useAppSelector(reducers => reducers.savePlaceReducer.place);

  // const dispatch = useDispatch();

  interface State {
    title: string;
    reference: string;
    city: string;
    street1: string;
    street2: string;
    houseNunmber: string;
  }

  const [values, setValues] = React.useState<State>({
    title: letter === Letters.PLACE_A ? 'CASA' : 'TRABAJO',
    reference: '',
    city: '',
    street1: '',
    street2: '',
    houseNunmber: '',
  });

  React.useEffect(() => {
    if (place) {
      setValues({
        title: place.title,
        reference: place.reference,
        city: place.city,
        street1: place.main_street,
        street2: place.second_street,
        houseNunmber: place.house_number,
      });
      const cLatLnt = {lat: place.latitude, lng: place.longitude}
      setLatLng(cLatLnt);
      setCenter(cLatLnt);
      setClicks([new google.maps.LatLng(cLatLnt)])
    }
  }, [place]);

  const callToOpen = React.useCallback(() => {
    // console.log('callToOpen()');
    setOpen(true);
  }, []);
  const pattern = /[^,0-9a-zA-Z áéíóúÁÉÍÓÚ]+/g;
  const onClick = (e: google.maps.MapMouseEvent) => {
    // avoid directly mutating state
    // setClicks([...clicks, e.latLng!]);
    // if (!open) {
      // console.log('click...')
      geocode({ address: `${values.street1} ${values.houseNunmber} ${values.street2}, ${values.city}, Central, Paraguay`.replace(pattern, "") })
    // }
    // setClicks([e.latLng!]);
  };

  const onIdle = (m: google.maps.Map) => {
    // console.log("onIdle");
    setZoom(m.getZoom()!);
    // setCenter(m.getCenter()!.toJSON());
  };
  // [END maps_react_map_component_app_state]

  // >>
  function clear() {
    // marker.setMap(null);
    // responseDiv.style.display = "none";
  }

  function geocode(request: google.maps.GeocoderRequest): void {
    clear();
  
    geocoder
      .geocode(request)
      .then((result) => {
        const { results } = result;
  
        // map.setCenter(results[0].geometry.location);
        // marker.setPosition(results[0].geometry.location);
        // marker.setMap(map);
        // responseDiv.style.display = "block";
        // response.innerText = JSON.stringify(result, null, 2);
        // console.log({results});
        if (results) {
          const opt = results[0];
          let location = opt.geometry.location
          // console.log(`${location.lat()}, ${location.lng()}`);
          // setTimeout(callToOpen, 400);
          setLatLng({lat: location.lat(), lng: location.lng()})
          callToOpen();
        }
        return results;
      })
      .catch((e) => {
        alert("Geocode was not successful for the following reason: " + e);
      });
  }

  const render = (status: Status) => {
    return <h1>{status}</h1>;
  };

  const handleChange =
    (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setValues({ ...values, [prop]: event.target.value });
    };

  const  onTagsChange = (event: object, vals: any) => {
    // console.log(values)
    setValues({ ...values, "city": vals });
    }

  const saveMapLocation = (latLng: LatLng) => {
    setCenter({lat: latLng.lat, lng: latLng.lng});
    setClicks([new google.maps.LatLng({lat: latLng.lat, lng: latLng.lng})]);
  }

  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackBar(false);
  };

  const action = (
    <React.Fragment>
      {/* <Button color="secondary" size="small" onClick={handleClose}>
        UNDO
      </Button> */}
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );
  
  const [message, setMessage] = React.useState('');
  
  return (
    <Container maxWidth="sm" style={appMarginTop}>
      <Typography gutterBottom variant="h5" component="div">
        {place ? 'Editar' : 'Agregar'} Lugar
      </Typography>
      
      <SeparatorView />

      <TextField
        fullWidth
        id="outlined-basic"
        label="Título"
        variant="outlined"
        value={values.title}
        onChange={handleChange('title')}
        />

      <SeparatorView />

      <TextField
          id="outlined-multiline-static"
          label="Referencia"
          multiline
          fullWidth
          rows={4}
          value={values.reference}
          onChange={handleChange('reference')}
        />

      <SeparatorView />

      <Autocomplete
          disablePortal
          id="combo-box-demo"
          value={values.city}
          options={[
            'Asunción',
            'Areguá',
            'Capiatá',
            'Fernando de la Mora',
            'Guarambaré',
            'Itá',
            'Itauguá',
            'Saldívar',
            'Lambaré',
            'Limpio',
            'Luque',
            'Mariano Roque Alonso',
            'Nueva Italia',
            'Ñemby',
            'San Antonio',
            'San Lorenzo',
            'Villa Elisa',
            'Villeta',
            'Ypacaraí',
            'Ypané',
          ]}
          onSelect={obj=>{console.log(obj)}}
          onChange={onTagsChange}
          renderInput={
            (params) => (
            <TextField
              {...params}
              label="Ciudad"
              value={values.city}
              onChange={handleChange('city')}
              />
            )
          }
        />

      <SeparatorView />

      <TextField
          id="outlined-multiline-static"
          label="Calle principal"
          fullWidth
          value={values.street1}
          onChange={handleChange('street1')}
        />

      <SeparatorView />

      <TextField
          id="outlined-multiline-static"
          label="Calle secundaria"
          fullWidth
          value={values.street2}
          onChange={handleChange('street2')}
        />

      <SeparatorView />


      <TextField
          id="outlined-multiline-static"
          label="Número de casa"
          fullWidth
          value={values.houseNunmber}
          onChange={handleChange('houseNunmber')}
        />

      <SeparatorView />

      {latLng && (
        <MapModal open={open} setOpen={setOpen} latLng={latLng} setLatLng={setLatLng} saveMapLocation={saveMapLocation} />
      )}

      <div>Mapa</div>
      <div style={{ display: "flex", height: "250px" }}>
        <Wrapper apiKey={GOOGLE_APY_KEY} render={render}>
          <Map
            center={center}
            onClick={onClick}
            onIdle={onIdle}
            zoom={zoom}
            style={{ flexGrow: "1", height: "100%" }}
          >
            {clicks.map((latLng, i) => (
              <Marker key={i} position={latLng} />
            ))}
          </Map>
        </Wrapper>
        {/* Basic form for controlling center and zoom of map. */}
        {/* {form} */}
      </div>

      <SeparatorView />

      <Button
          fullWidth
          variant="contained"
          onClick={() => {
            // console.log('guardar...');
            if (latLng) {
              PlacesService.savePlace({
                title: values.title,
                reference: values.reference,
                city: values.city,
                latitude: latLng.lat.toString(),
                longitude: latLng.lng.toString(),
                main_street: values.street1,
                second_street: values.street2,
                house_number: values.houseNunmber,
                id: place && place.id ? place.id.toString() : '',
                place_letter: letter && letter === Letters.PLACE_B ? 'B' : 'A',
              })
                .then( response => {
                  if (response.place) {
                    dispatch(getUserAction());
                    history.goBack();
                  } else {
                    setMessage(response.errors.join());
                    setOpenSnackBar(true);
                  }
                })
                .catch(e => {
                  // console.error(e);
                });
              // dispatch(savePlaceAction());
            }
          }}
          >
            GUARDAR
        </Button>
        <Snackbar
          open={openSnackBar}
          autoHideDuration={6000}
          onClose={handleClose}
          // message={message}
          action={action}
        >
          <Alert severity="error">
            {message}
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleClose}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </Alert>
        </Snackbar>
    </Container>
  );
}

//>>
interface MapProps extends google.maps.MapOptions {
  style: { [key: string]: string };
  onClick?: (e: google.maps.MapMouseEvent) => void;
  onIdle?: (map: google.maps.Map) => void;
}

const Map: React.FC<MapProps> = ({
  onClick,
  onIdle,
  children,
  style,
  ...options
}) => {
  // [START maps_react_map_component_add_map_hooks]
  const ref = React.useRef<HTMLDivElement>(null);
  const [map, setMap] = React.useState<google.maps.Map>();

  React.useEffect(() => {
    if (ref.current && !map) {
      setMap(new window.google.maps.Map(ref.current, {}));
    }
  }, [ref, map]);
  // [END maps_react_map_component_add_map_hooks]

  // [START maps_react_map_component_options_hook]
  // because React does not do deep comparisons, a custom hook is used
  // see discussion in https://github.com/googlemaps/js-samples/issues/946
  useDeepCompareEffectForMaps(() => {
    if (map) {
      map.setOptions(options);
    }
  }, [map, options]);
  // [END maps_react_map_component_options_hook]

  // [START maps_react_map_component_event_hooks]
  React.useEffect(() => {
    if (map) {
      ["click", "idle"].forEach((eventName) =>
        google.maps.event.clearListeners(map, eventName)
      );

      if (onClick) {
        map.addListener("click", onClick);
      }

      if (onIdle) {
        // map.addListener("idle", () => onIdle(map));
      }
    }
  }, [map, onClick, onIdle]);
  // [END maps_react_map_component_event_hooks]

  // [START maps_react_map_component_return]
  return (
    <>
      <div ref={ref} style={style} />
      {React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          // set the map prop on the child component
          return React.cloneElement(child, { map });
        }
      })}
    </>
  );
  // [END maps_react_map_component_return]
};

// [START maps_react_map_marker_component]
const Marker: React.FC<google.maps.MarkerOptions> = (options) => {
  const [marker, setMarker] = React.useState<google.maps.Marker>();

  React.useEffect(() => {
    if (!marker) {
      setMarker(new google.maps.Marker());
    }

    // remove marker from map on unmount
    return () => {
      if (marker) {
        marker.setMap(null);
      }
    };
  }, [marker]);

  React.useEffect(() => {
    if (marker) {
      marker.setOptions(options);
    }
  }, [marker, options]);

  return null;
};
// [END maps_react_map_marker_component]

// const deepCompareEqualsForMaps = createCustomEqual(
//   (deepEqual) => (a: any, b: any) => {
//     if (
//       isLatLngLiteral(a) ||
//       a instanceof google.maps.LatLng ||
//       isLatLngLiteral(b) ||
//       b instanceof google.maps.LatLng
//     ) {
//       return new google.maps.LatLng(a).equals(new google.maps.LatLng(b));
//     }

//     // TODO extend to other types

//     // use fast-equals for other objects
//     return deepEqual(a, b);
//   }
// );

// function useDeepCompareMemoize(value: any) {
//   const ref = React.useRef();

//   if (!deepCompareEqualsForMaps(value, ref.current)) {
//     ref.current = value;
//   }

//   return ref.current;
// }

function useDeepCompareEffectForMaps(
  callback: React.EffectCallback,
  dependencies: any[]
) {
  React.useEffect(callback, [callback]);
}

// window.addEventListener("DOMContentLoaded", () => {
//   ReactDom.render(<App />, document.getElementById("root"));
// });

// [END maps_react_map]
// let PRESERVE_COMMENT_ABOVE; // force tsc to maintain the comment above eslint-disable-line

export {};