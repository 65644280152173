import {
  USER_LOADING,
  USER_SUCCESS,
  USER_FAIL,
  UserDispatchTypes,
} from '../types/user';

import OAuthService from '../../config/services/loginServices';
import {Dispatch} from 'redux';

export const getUserAction =
  () => async (dispatch: Dispatch<UserDispatchTypes>) => {

    dispatch({
      type: USER_LOADING,
    });

    OAuthService.me()
      .then( user => {
        dispatch({
          type: USER_SUCCESS,
          payload: user,
        });
      })
      .catch(e => {
        dispatch({
          type: USER_FAIL,
        });
      });
  };
