import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Place from '../../data/model/Place';
import CSS from "csstype";

interface Props {
  place?: Place;
  placeholder?: string;
  onAction: (place?: Place) => void;
}

export default function PlaceCard({place, placeholder, onAction}: Props) {
  return (
    <Card style={place ? greenBorder : redBorder}>
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          {place ? place.title : placeholder ? placeholder : 'Lugar A'}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {place ? `${place.main_street} ${place.house_number} ${place.second_street}, ${place.city}` : ''}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {place ? place.reference : 'Debe agregar un lugar.'}
        </Typography>
      </CardContent>
      <CardActions>
        <Button
          style={actionBtnStyle}
          size="small"
          onClick={() => {
            onAction(place);
          }}
          >
            {place ? ('Editar') : ('Agregar lugar')}
        </Button>
      </CardActions>
    </Card>
  );
}

const redBorder: CSS.Properties = {
  borderWidth: '2px',
  borderColor: 'red',
  borderStyle: 'solid',
};

const greenBorder: CSS.Properties = {
  // borderWidth: '2px',
  // borderColor: 'green',
  // borderStyle: 'solid',
};

const actionBtnStyle: CSS.Properties = {
  marginLeft: 'auto',
};