import User from '../../model/User';
import {
  USER_LOADING,
  USER_SUCCESS,
  USER_FAIL,
  USER_RESET,
  UserDispatchTypes,
} from '../types/user';

interface IinitialState {
  user: User | undefined;
  loading: boolean;
  error: boolean;
  logedIn: boolean;
}

const initialState: IinitialState = {
  user: undefined,
  loading: false,
  error: false,
  logedIn: false,
};
const userReducer = (
  state: IinitialState = initialState,
  action: UserDispatchTypes,
): IinitialState => {
  switch (action.type) {
    case USER_FAIL:
      return {...state, loading: false, error: true};
    case USER_LOADING:
      return {...state, loading: true};
    case USER_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload,
        error: false,
        logedIn: true,
      };
    case USER_RESET:
        return {
          ...state,
          loading: false,
          user: undefined,
          error: false,
          logedIn: false,
        };
    default:
      return state;
  }
};

export default userReducer;