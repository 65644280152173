import React, { useEffect } from "react";
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import MapIcon from '@mui/icons-material/Map';
import ArrowBack from '@mui/icons-material/ArrowBack';
// import ModeOfTravelIcon from '@mui/icons-material/ModeOfTravel';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import HomeIcon from '@mui/icons-material/Home';
import ReceiptIcon from '@mui/icons-material/Receipt';
import Paper from '@mui/material/Paper';
import ReactGA from 'react-ga';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  useHistory,
  useLocation,
  Redirect,
} from "react-router-dom";
import { homeLink, loginLink, placesLink, registerLink, routes, inicioLink, faqLink } from "./routes";
import { AppBar, Badge, Button, IconButton, ThemeProvider, Toolbar, Typography } from "@mui/material";
// import MenuIcon from '@mui/icons-material/Menu';
import SeparatorView from "./ui/components/SeparatorView";
import { useDispatch } from "react-redux";
import { getUserAction } from "./data/redux/actions/userActions";
import { useAppSelector } from "./data/redux/stores/hooks";
import { customTheme } from "./assets/theme/customTheme";
import { ACCESS_TOKEN_KEY } from "./data/config/services/Client";
// import { WhatsApp } from "@mui/icons-material";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
// import { ACCESS_TOKEN_KEY } from "./data/config/services/Client";
// import { USER_RESET } from "./data/redux/types/user";

function FixedBottomNavigation() {
  const [natTabIndex, setNavTabIndex] = React.useState(4);
  const [notCount, setNotCount] = React.useState(0);
  const ref = React.useRef<HTMLDivElement>(null);
  const history = useHistory();
  const location = useLocation();
  // console.log({location});
  const logedIn = localStorage.getItem(ACCESS_TOKEN_KEY); //useAppSelector(state => state.userReducer.logedIn);

  const user = useAppSelector(state => state.userReducer.user);
  
  /* React.useEffect(() => {
    (ref.current as HTMLDivElement).ownerDocument.body.scrollTop = 0;
    setMessages(refreshMessages());
  }, [value, setMessages]); */

  const dispatch = useDispatch();

  useEffect(() => {
    if (user === undefined) {
      dispatch(getUserAction());
    }
  });

  useEffect(() => {
    if (user) {
      let count = 0;
      if (!user.journey || !user.journey.place_a) count++;
      if (!user.journey || !user.journey.place_b) count++;
      if (!user.journey || !user.journey.time_to_arrive) count++;
      if (!user.journey || !user.journey.exit_time) count++;
      setNotCount(count);
    } else {
      setNotCount(4);
    }
  }, [user]);

  useEffect(() => {
    ReactGA.initialize('AIzaSyAo8398uWrLCPWQWmC-8PspXcarEhonXis');
  }, [])

  useEffect(() => {
    history.listen((location) => {
      // console.log({location});
      ReactGA.pageview(window.location.pathname + window.location.search);
    });
  }, [history]);

  return (
    <ThemeProvider theme={customTheme}>
      <Box sx={{ pb: 7 }} ref={ref}>
        <CssBaseline />
        <Box sx={{ flexGrow: 1 }}>
          <AppBar position="fixed">
            <Toolbar>
              {(
                location.pathname !== homeLink.path &&
                location.pathname !== inicioLink.path &&
                location.pathname !== loginLink.path &&
                location.pathname !== faqLink.path
              ) && (
                <IconButton
                  size="large"
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  sx={{ mr: 2 }}
                  onClick={() => {
                    history.goBack();
                  }}
                >
                  <ArrowBack />
                </IconButton>
              )}
              {(location.pathname === loginLink.path) && (
                <IconButton
                  size="large"
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  sx={{ mr: 2 }}
                  onClick={() => {
                    document.location.href = homeLink.path;
                  }}
                >
                  <HomeIcon />
                </IconButton>
              )}
              <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                {location.pathname === registerLink.path && ('TUP | Registro de Usuario')}
                {location.pathname === placesLink.path && ('TUP | Lugares')}
                {location.pathname === inicioLink.path && ('¡Te damos la bienvenida!')}
                {location.pathname === faqLink.path && ('Preguntas Frecuentes')}
              </Typography>
              {location.pathname !== loginLink.path && location.pathname !== registerLink.path && !logedIn && (
                <>
                  <Button
                    color="inherit"
                    onClick={() => {
                      // setNavTabIndex(-1);
                      history.push(registerLink.path);
                      ReactGA.event({
                        category: 'SESSION',
                        action: 'go to register screen'
                      });
                    }}>
                    REGISTRARSE
                  </Button>
                  <Button
                    color="inherit"
                    onClick={() => {
                      // setNavTabIndex(-1);
                      ReactGA.event({
                        category: 'SESSION',
                        action: 'go to login screen'
                      });
                      history.push(loginLink.path);
                    }}>
                    INICIAR SESIÓN
                  </Button>
                </>
              )}
              {/* {location.pathname !== loginLink.path && user !== undefined && (
                <Button
                  color="inherit"
                  onClick={() => {
                    localStorage.removeItem(ACCESS_TOKEN_KEY);
                    dispatch({type: USER_RESET});
                    window.location.href='./';
                  }}>
                  Cerrar sesión
                </Button>
              )} */}
            </Toolbar>
          </AppBar>
        </Box>

        <SeparatorView />
        <Switch>
          {routes.map((route, i) => (
            <RouteWithSubRoutes key={i} {...route} />
          ))}
        </Switch>
        {
          location.pathname !== loginLink.path &&
          location.pathname !== registerLink.path && (
        <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
          <BottomNavigation
            showLabels
            value={natTabIndex}
            onChange={(event, newValue) => {
              setNavTabIndex(newValue);
              let route = routes[newValue];
              // window.history.pushState({}, `Lugar ${newValue}`, route.path);
              if (newValue === 4) {
                window.location.href="https://wa.me/595976555554?text=Hola%20les%20contacto%20desde%20la%20web: "
              } else {
                history.push(route.path);
              }
            }}
          >
            <BottomNavigationAction label="Inicio" icon={<HomeIcon />} />
            <BottomNavigationAction label="Preguntas" icon={<HelpOutlineIcon />} />
            <BottomNavigationAction label="Lugares" icon={
              <Badge badgeContent={notCount} color="warning">
                <MapIcon />
              </Badge>
            } />
            <BottomNavigationAction label="Cuenta" icon={<ReceiptIcon />} />
            <BottomNavigationAction label="Contacto" icon={<WhatsAppIcon />} />
          </BottomNavigation>
        </Paper>
        )}
      </Box>
    </ThemeProvider>
  );
}

export default function RouteConfig() {
  return (
    <Router>
      <div>
        <FixedBottomNavigation />
      </div>
    </Router>
  );
}

//
// A special wrapper for <Route> that knows how to
// handle "sub"-routes by passing them in a `routes`
// prop to the component it renders.
function RouteWithSubRoutes(route: any) {
  const location = useLocation();
  const logedIn = localStorage.getItem(ACCESS_TOKEN_KEY); //useAppSelector(state => state.userReducer.logedIn);
  if (
      !logedIn &&
      location.pathname !== loginLink.path &&
      location.pathname !== registerLink.path &&
      location.pathname !== homeLink.path &&
      location.pathname !== faqLink.path
    ) {
    return (
      <Redirect to={loginLink.path} />
    );
  } else if (logedIn && location.pathname === loginLink.path) {
    return (
      <Redirect to={homeLink.path} />
    );
  } else {
    return (
      <Route
        path={route.path}
        render={props => (
          // pass the sub-routes down to keep nesting
          <route.component {...props} routes={route.routes} />
        )}
      />
    );
  }
}
