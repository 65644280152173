import axios from 'axios';
import {HOST_NAME} from '../api/host';

export const ACCESS_TOKEN_KEY = 'ACCESS_TOKEN_KEY';

const accessToken = localStorage.getItem(ACCESS_TOKEN_KEY);

export default axios.create({
  baseURL: `${HOST_NAME}`,
  headers: {
    'Accept': 'application/json',
    'Authorization': `Bearer ${accessToken}`
  },
});
