import {Container} from "@mui/material";
import {appMarginTop} from "../../assets/css/globalCss";
import React from "react";
import CustomizedAccordions from "./home/faq";
import CustomizedSteepper from "./home/CustomizedStepper";

export default function TravelsScreen() {

  const [showStepper] = React.useState(false);

  return (
    <Container maxWidth="sm" style={appMarginTop}>
      {showStepper ? (
        <CustomizedSteepper />
      ) : (
        <>
          {/* <div style={imageCont}>
            <img
              src={img}
              alt='TUP'
              style={imageStyle}
            />
          </div> */}
          <CustomizedAccordions />
        </>
      )}

      
    </Container>
  );
}
