import {AxiosResponse} from 'axios';
import { SavePlaceResponse, SavePlaceParam } from '../../dto/interfaces';
import client from './Client';

const PlacesService = {
  savePlace: async (params: SavePlaceParam): Promise<SavePlaceResponse> => {
    // console.log('PlacesService.savePlace()', {params});
    return client
      .post('/api/v1/place/save', params)
      .then((res: AxiosResponse<SavePlaceResponse>) => {
        // console.log('PlacesService.savePlace()', {res})
        return res.data;
      })
      .catch(e => {
        // console.error('PlacesService.savePlace()', e);
        return e;
      });
  },
};

export default PlacesService;
