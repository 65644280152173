import Place from "../../model/Place";

export const PLACE_LOADING = 'PLACE_LOADING';
export const PLACE_SUCCESS = 'PLACE_SUCCESS';
export const PLACE_FAIL = 'PLACE_FAIL';
export const PLACE_RESET = 'PLACE_RESET';
export const PLACE_LETTER = 'PLACE_LETTER';
export const SET_PLACE = 'SET_PLACE';
export enum Letters{PLACE_A = 'PLACE_A', PLACE_B = 'PLACE_B'};

export const IS_PLACE_LOADING = 'IS_PLACE_LOADING';

export interface PlaceLoading {
  type: typeof PLACE_LOADING;
}

export interface PlaceFail {
  type: typeof PLACE_FAIL;
}

export interface PlaceSuccess {
  type: typeof PLACE_SUCCESS;
  payload: Place;
}

export interface PlaceLetter {
  type: typeof PLACE_LETTER;
  payload: Letters;
}

export interface PlaceReset {
  type: typeof PLACE_RESET;
}

export interface SetPlace {
  type: typeof SET_PLACE;
  payload: Place;
}

export type PlaceDispatchTypes =
  | PlaceLoading
  | PlaceFail
  | PlaceSuccess
  | PlaceReset
  | SetPlace
  | PlaceLetter;