import { createTheme } from "@mui/material";
import { primaryColor, secondaryColor } from "../color/palete";

export const customTheme = createTheme({
  palette: {
    primary: {
      // Purple and green play nicely together.
      main: primaryColor,
    },
    secondary: {
      // This is green.A700 as hex.
      main: secondaryColor,
    },
  },
});
