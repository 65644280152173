import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { DialogContent } from '@mui/material';
import { GOOGLE_APY_KEY } from '../../data/google';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

type LatLng = {
  lat: number,
  lng: number,
}

interface Props {
  setOpen: (open: boolean) => void;
  open: boolean;
  latLng: LatLng;
  setLatLng: (latLng: LatLng) => void;
  saveMapLocation: (latLng: LatLng) => void;
}

export default function MapModal({open, setOpen, latLng, setLatLng, saveMapLocation}: Props) {
  const [clicks, setClicks] = React.useState<google.maps.LatLng[]>([new google.maps.LatLng(latLng)]);
  const [zoom, setZoom] = React.useState(18); // initial zoom
  const [center, setCenter] = React.useState<google.maps.LatLngLiteral>(latLng);
  
  // const handleClickOpen = () => {
  //   setOpen(true);
  // };

  const handleClose = () => {
    saveMapLocation({lat: clicks[0].lat(), lng: clicks[0].lng()});
    setLatLng({lat: clicks[0].lat(), lng: clicks[0].lng()});
    setOpen(false);
  };


  const render = (status: Status) => {
    return <h1>{status}</h1>;
  };

  const onClick = (e: google.maps.MapMouseEvent) => {
    setClicks([e.latLng!]);
    if (e.latLng) {
      setCenter(e.latLng as unknown as google.maps.LatLngLiteral);
    }
  };

  const onIdle = (m: google.maps.Map) => {
    // console.log("onIdle");
    setZoom(m.getZoom()!);
    setCenter(m.getCenter()!.toJSON());
  };

  return (
    <div>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Lugar
            </Typography>
            <Button autoFocus color="inherit" onClick={handleClose}>
              GUARDAR
            </Button>
          </Toolbar>
        </AppBar>
        <DialogContent style={{padding: 0}}>
          <div style={{ display: "flex", height: "100%" }}>
            <Wrapper apiKey={GOOGLE_APY_KEY} render={render}>
              <Map
                center={center}
                onClick={onClick}
                onIdle={onIdle}
                zoom={zoom}
                fullscreenControl={false}
                gestureHandling={"greedy"}
                style={{ flexGrow: "1", height: "100%" }}
              >
                {clicks.map((latLng, i) => (
                  <Marker key={i} position={latLng} />
                ))}
              </Map>
            </Wrapper>
            {/* Basic form for controlling center and zoom of map. */}
            {/* {form} */}
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

//>>
interface MapProps extends google.maps.MapOptions {
  style: { [key: string]: string };
  onClick?: (e: google.maps.MapMouseEvent) => void;
  onIdle?: (map: google.maps.Map) => void;
  fullscreenControl?: boolean|null;
  gestureHandling?: string|null;
}

const Map: React.FC<MapProps> = ({
  onClick,
  onIdle,
  children,
  style,
  ...options
}) => {
  // [START maps_react_map_component_add_map_hooks]
  const ref = React.useRef<HTMLDivElement>(null);
  const [map, setMap] = React.useState<google.maps.Map>();

  React.useEffect(() => {
    if (ref.current && !map) {
      setMap(new window.google.maps.Map(ref.current, {}));
    }
  }, [ref, map]);
  // [END maps_react_map_component_add_map_hooks]

  // [START maps_react_map_component_options_hook]
  // because React does not do deep comparisons, a custom hook is used
  // see discussion in https://github.com/googlemaps/js-samples/issues/946
  useDeepCompareEffectForMaps(() => {
    if (map) {
      map.setOptions(options);
    }
  }, [map, options]);
  // [END maps_react_map_component_options_hook]

  // [START maps_react_map_component_event_hooks]
  React.useEffect(() => {
    if (map) {
      ["click", "idle"].forEach((eventName) =>
        google.maps.event.clearListeners(map, eventName)
      );

      if (onClick) {
        map.addListener("click", onClick);
      }

      if (onIdle) {
        // map.addListener("idle", () => onIdle(map));
      }
    }
  }, [map, onClick, onIdle]);
  // [END maps_react_map_component_event_hooks]

  // [START maps_react_map_component_return]
  return (
    <>
      <div ref={ref} style={style} />
      {React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          // set the map prop on the child component
          return React.cloneElement(child, { map });
        }
      })}
    </>
  );
  // [END maps_react_map_component_return]
};

// [START maps_react_map_marker_component]
const Marker: React.FC<google.maps.MarkerOptions> = (options) => {
  const [marker, setMarker] = React.useState<google.maps.Marker>();

  React.useEffect(() => {
    if (!marker) {
      setMarker(new google.maps.Marker());
    }

    // remove marker from map on unmount
    return () => {
      if (marker) {
        marker.setMap(null);
      }
    };
  }, [marker]);

  React.useEffect(() => {
    if (marker) {
      marker.setOptions(options);
    }
  }, [marker, options]);

  return null;
};
// [END maps_react_map_marker_component]

// const deepCompareEqualsForMaps = createCustomEqual(
//   (deepEqual) => (a: any, b: any) => {
//     if (
//       isLatLngLiteral(a) ||
//       a instanceof google.maps.LatLng ||
//       isLatLngLiteral(b) ||
//       b instanceof google.maps.LatLng
//     ) {
//       return new google.maps.LatLng(a).equals(new google.maps.LatLng(b));
//     }

//     // TODO extend to other types

//     // use fast-equals for other objects
//     return deepEqual(a, b);
//   }
// );

// function useDeepCompareMemoize(value: any) {
//   const ref = React.useRef();

//   if (!deepCompareEqualsForMaps(value, ref.current)) {
//     ref.current = value;
//   }

//   return ref.current;
// }

function useDeepCompareEffectForMaps(
  callback: React.EffectCallback,
  dependencies: any[]
) {
  React.useEffect(callback, [callback]);
}

// window.addEventListener("DOMContentLoaded", () => {
//   ReactDom.render(<App />, document.getElementById("root"));
// });

// [END maps_react_map]
// let PRESERVE_COMMENT_ABOVE; // force tsc to maintain the comment above eslint-disable-line

export {};