import { Visibility, VisibilityOff } from "@mui/icons-material";
import img from '../../assets/images/logo_tup.jpg';
import {
  Alert,
  Button,
  Card,
  CardActions,
  CardContent,
  Container,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import CSS from "csstype";
import React from "react";
import SeparatorView from "../components/SeparatorView";
// import OAuthService from "../../data/config/services/loginServices";
import client, { ACCESS_TOKEN_KEY } from '../../data/config/services/Client';
// import { useAppDispatch } from "../../data/redux/stores/hooks";

// import { useAppSelector, useAppDispatch } from '../../app/hooks';
// import {
//   decrement,
//   increment,
//   incrementByAmount,
//   incrementAsync,
//   incrementIfOdd,
//   selectCount,
// } from '../../features/counter/counterSlice';

// import {getUserAction} from '../../data/redux/actions/userActions';
import { appMarginTop } from "../../assets/css/globalCss";
import { useHistory } from "react-router-dom";
import { placesLink, registerLink } from "../../routes";
import { LoginResponse } from "../../data/dto/interfaces";
import { AxiosError, AxiosResponse } from "axios";

interface State {
  username: string;
  password: string;
  weight: string;
  weightRange: string;
  showPassword: boolean;
}

export default function LoginScreen() {
  const history = useHistory();
  // const count = useAppSelector(selectCount);
  // const dispatch = useAppDispatch();
  // const [incrementAmount, setIncrementAmount] = useState('2');

  // const incrementValue = Number(incrementAmount) || 0;

  const [responseErrors, setResponseErrors] = React.useState<string[]>([]);
  const [showSnackBar, setShowSnackBar] = React.useState(false);
  const [values, setValues] = React.useState<State>({
    username: '',
    password: '',
    weight: '',
    weightRange: '',
    showPassword: false,
  });

  const handleChange =
    (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setValues({ ...values, [prop]: event.target.value });
    };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setShowSnackBar(false);
  };

  const postLoginData = () => {
    client
      .post('/api/v1/login', {
        username: values.username,
        password: values.password,
        // grant_type: "password",
        // client_id: 2,
        // client_secret: "jH7hf105lDwPs3BTpG7SJxP7vByGslT3LLN1Ss1p",
        // scope: "*",
      })
      .then((res: AxiosResponse<LoginResponse>) => {
        // console.log('LoginScreen', {res})
        if (res.data.token !== undefined) {
          localStorage.setItem(ACCESS_TOKEN_KEY, res.data.token);
        }
        switch (res.status) {
          case 200:
            const response = res.data;
            if (response.errors && response.errors.length > 0) {
              // console.error(response.errors);
              setResponseErrors(response.errors);
              setShowSnackBar(true);
            } else if(response.token && response.token.length > 0) {
              window.location.href = placesLink.path;
            }else {
            }
            break;
          default:
            // console.log(res);
            break;
        }
      })
      .catch((e: AxiosError) => {
        console.error('LoginScreen', e.message);
        // console.error('LoginScreen', e.response?.data);
        if (e.response && e.response.data) {
          setResponseErrors(e.response.data.errors);
        } else {
          setResponseErrors([e.message]);
        }
        setShowSnackBar(true);
      });
  };

  return (
    <Container maxWidth="sm" style={appMarginTop}>
      <div style={imageCont}>
        <img
          src={img}
          alt='TUP'
          style={imageStyle}
        />
      </div>
      <TextField
        label="Email"
        id="outlined-size-normal"
        fullWidth
        value={values.username}
        onChange={handleChange('username')}
        />

      <FormControl sx={{ mt: 1, width: '100%' }} variant="outlined">
          <InputLabel htmlFor="outlined-adornment-password">Contraseña</InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
            type={values.showPassword ? 'text' : 'password'}
            value={values.password}
            fullWidth
            onChange={handleChange('password')}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {values.showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            label="Contraseña"
          />
        </FormControl>
        <SeparatorView />
        <Button
          fullWidth
          variant="contained"
          onClick={() => {
            if (values.username.length === 0) {
              setResponseErrors(['ingrese un email o CI']);
              setShowSnackBar(true);
            } else if (values.password.length === 0) {
              setResponseErrors(['debe ingresar una contraseña']);
              setShowSnackBar(true);
            } else {
              postLoginData()
            }
          }}
          >
            INGRESAR
        </Button>

        <SeparatorView />

        <Card sx={{ minWidth: 275 }}>
          <CardContent>
            <Typography style={{textAlign:'center'}} sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
              ¿Aún no tienes una cuenta?
            </Typography>
          </CardContent>
          <CardActions>
            <Button
              style={{margin: 'auto'}}
              size="small"
              onClick={() => {
                history.push(registerLink.path)
              }}>
              Regístrate aquí
            </Button>
          </CardActions>
        </Card>
        <Snackbar
          open={showSnackBar}
          autoHideDuration={6000}
          onClose={handleClose}
          >
          <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
            {responseErrors ? responseErrors.join(', ') : 'Error indefinido'}
          </Alert>
        </Snackbar>
    </Container>
  );
}
  
const imageStyle: CSS.Properties = {
  width: "50%",
  margin: 'auto',
};

const imageCont: CSS.Properties = {
  textAlign: 'center',
  marginBottom: '15px',
};
